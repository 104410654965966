import React from "react"
import { graphql, Link } from "gatsby"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"

//Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Title from "../components/title"

//styles
import { Container, Col, Button } from "react-bootstrap"
import * as styles from "../styles/templates/blog.module.scss"

export const query = graphql`
  query ($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      date(formatString: "MMM DD, YYYY")
      image {
        gatsbyImageData(quality: 100, width: 600, layout: CONSTRAINED)
      }
      slug
      title
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            title
            description
            gatsbyImageData(width: 700)
            __typename
          }
        }
      }
    }
  }
`
const Blog = ({ data }) => {
  const Bold = ({ children }) => (
    <span>
      <strong>{children}</strong>
    </span>
  )
  const Text = ({ children }) => <p className={styles.text}>{children}</p>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const { gatsbyImageData, description } = node.data.target

        return (
          <div>
            <GatsbyImage
              image={gatsbyImageData}
              alt={description}
              className="mb-4"
            />
          </div>
        )
      },
    },
  }
  const blog = data.contentfulBlogPost

  return (
    <Layout>
      {/* Add SEO Here */}
      <SEO />
      <Title title={blog.title} date={blog.date} />
      <Container className={`text-center px-5 ${styles.content}`}>
        <Col lg={8} className="mx-auto">
          <GatsbyImage image={blog.image.gatsbyImageData} alt={blog.title} />
        </Col>
        <Col lg={6} className="mx-auto text-start my-5">
          {/* Show Registration Form - Commercial Discount */}
          <div className="text-center">
            {blog.slug === "commercial-discount" && (
              <Link to="/discount">
                <Button variant="primary">Register Now</Button>
              </Link>
            )}
          </div>

          <h1 className="d-flex align-items-center text-center mt-5 pt-5">
            {blog.title}
          </h1>

          <div className={`mt-3 `}>{renderRichText(blog.content, options)}</div>
          {/* Show Registration Form - Commercial Discount */}
          <div className="text-center">
            {blog.slug === "commercial-discount" && (
              <Link to="/discount">
                <Button variant="primary">Register Now</Button>
              </Link>
            )}
          </div>
        </Col>
      </Container>
    </Layout>
  )
}

export default Blog
